<template>
  <div class="contract-fee-modal">
    <sweet-modal ref="modal" title="계약 수수료 입금정보" overlay-theme="dark">
      <template v-if="contract">
        <div class="contract-fee-content">{{ contract.fee_content }}</div>
        <button v-if="contract.fee_status === 0" class="button is-primary" @click="clickDone">계약수수료 입금완료</button>
      </template>
    </sweet-modal>
  </div>
</template>
<script>
import { SweetModal } from 'sweet-modal-vue';
import PartnerAPIMixin from '../../mixins/PartnerAPIMixin';
export default {
  name: "ContractFeeModal",
  components: {
    SweetModal
  },
  mixins: [
    PartnerAPIMixin
  ],
  props: {},
  data() {
    return {
      contract: undefined
    }
  },
  methods: {
    open(contract) {
      this.contract = contract;
      this.$refs.modal.open();
    },
    clickDone() {
      this.request.partner.patch(`launchpack/contract/${this.contract.id}`, { fee_status: 1 }).then(() => {
        this.contract.fee_status = 1;
        this.toast('계약수수료 입금완료 처리되었습니다.');
        this.$emit('done');
        this.$refs.modal.close();
      });

    }
  }
}
</script>
<style lang="stylus" scoped>
.contract-fee-content
  white-space pre-wrap
.button
  width 100%
  margin-top 20px
  font-size 14px
</style>
<style lang="stylus">
.contract-fee-modal .sweet-modal .sweet-title
  border-bottom 0
</style>