<template>
  <div class="my-mettings">
    <div class="container">
      <div class="mettings-title">계약 내역</div>
      <div class="tabs">
        <div
          class="tab unselect"
          v-for="(tab, index) in tabs"
          :key="`tab-${index}`"
          @click="clickTab(tab.value)"
          :class="{ active: selectedStatus === tab.value }">
          {{ tab.label }}
        </div>
      </div>
      <div class="list-wrapper">
        <div class="body4 sub total-count" v-if="page.total > 0">전체 {{ page.total }}개</div>
        <div class="list">
          <div class="list-header">
            <div class="list-header-item">서비스</div>
            <div class="list-header-item">계약금</div>
            <div class="list-header-item">계약일</div>
            <div class="list-header-item">상태</div>
            <div class="list-header-item">채팅/추가메뉴</div>
          </div>
          <div class="list-item" v-for="(item, index) in list" :key="`item-${index}`">
            <card-contract :card="item" @clicked="onClickCardButton" />
          </div>
          <div class="empty-view" v-if="list.length === 0">
            <div class="empty-view-text">등록된 계약이 없습니다.</div>
          </div>
        </div>
        <lp-paginate v-if="page.total_page > 1" :filtered="page" @getData="getData" />
      </div>
    </div>
    <contract-modal ref="contractModal" @done="getData" />
    <!-- 수수료 입금 모달 -->
    <contract-fee-modal ref="feeModal" @done="getData" />
    <!-- 계약서 파일 업로드 모달  -->
    <contract-file-upload-modal ref="fileModal" @done="getData" />
    <!-- 고객정보 모달 -->
    <contract-user-modal ref="userModal" />
    <!-- 작업 리스트 변경 모달 -->
    <contract-task-list-modal ref="taskListModal" @done="getData" />
    <!-- 후기 작성 모달 -->
    <temp-review-modal ref="reviewModal" :product="product" review-type="pd" @done="getData" />
  </div>
</template>
<script>
import CardContract from '@/components/component/CardContract.vue';
import UserAPIMixin from '../../mixins/UserAPIMixin';
import PartnerAPIMixin from '../../mixins/PartnerAPIMixin';
import LpPaginate from '@/components/component/LpPaginate';
import ContractModal from '@/components/component/ContractModal.vue';
import ContractFeeModal from '@/components/modal/ContractFeeModal.vue';
import ContractFileUploadModal from '@/components/modal/ContractFileUploadModal.vue';
import ContractTaskListModal from '@/components/modal/ContractTaskListModal.vue';
import ContractUserModal from '@/components/modal/ContractUserModal.vue';
import TempReviewModal from '@/components/modal/TempReviewModal.vue';
export default {
  name: 'MyContracts',
  components: {
    CardContract,
    LpPaginate,
    ContractFeeModal,
    ContractFileUploadModal,
    ContractTaskListModal,
    ContractUserModal,
    TempReviewModal,
    ContractModal,
  },
  mixins: [UserAPIMixin, PartnerAPIMixin],
  props: {},
  data() {
    return {
      selectedStatus: '',
      tabs: [
        {
          label: '전체',
          value: '',
        },
        {
          label: '계약요청',
          value: 0,
        },
        {
          label: '계약 진행예정',
          value: 1,
        },
        {
          label: '계약진행중',
          value: 2,
        },
        {
          label: '계약완료',
          value: 3,
        },
        {
          label: '계약 이행중',
          value: 4,
        },
        {
          label: '계약 이행완료',
          value: 5,
        },
        {
          label: '계약 취소',
          value: '100,101,102',
        },
      ],
      page: {
        cur_page: 1,
        total: 0,
        total_page: 0,
        page_length: 10,
      },
      list: [],
      product: undefined,
    };
  },
  computed: {},
  watch: {},
  created() {
    if (this.isLogin) {
      if (this.$route.query.status) {
        this.selectedStatus = Number(this.$route.query.status);
      }
      this.getData();
    }
  },
  methods: {
    onClickCardButton(data) {
      // type : file, link, status_3, status_4, fee, task, task_list, sign, review
      const type = data.type;
      const card = data.card;
      if (type === 'condition') {
        // 계약 조건 모달
        this.$refs.contractModal.open(card.id);
      } else if (type === 'file') {
        // 계약서 파일 모달
        this.$refs.fileModal.open(card);
      } else if (type === 'link') {
        // 계약서 링크 이동
        window.open(card.link, '_blank');
      } else if (type === 'deposit_status_2') {
        if (confirm('계약금을 입금을 확인하셨습니까?')) {
          // 상태 변경
          const status = type.split('deposit_status_')[1];
          this.request.partner
            .patch(`/launchpack/contract/${card.id}`, {
              deposit_status: Number(status),
              not_msg: true,
            })
            .then(() => {
              this.toast('변경되었습니다.');
              this.getData();
            });
        }
      } else if (type === 'deposit_status_1') {
        if (confirm('계약금을 입금완료하셨습니까?')) {
          // 상태 변경
          const status = type.split('deposit_status_')[1];
          this.request.user
            .patch(`/launchpack/contract/${card.id}`, {
              status: card.status,
              deposit_status: Number(status),
              not_msg: true,
            })
            .then(() => {
              this.toast('변경되었습니다.');
              this.getData();
            });
        }
      } else if (type.includes('status')) {
        if (confirm('계약 상태를 변경하시겠습니까?')) {
          // 상태 변경
          const status = type.split('_')[1];
          this.request.partner
            .patch(`/launchpack/contract/${card.id}`, {
              status: status,
              not_msg: true,
            })
            .then(() => {
              this.toast('변경되었습니다.');
              this.getData();
            });
        }
      } else if (type === 'fee') {
        // 수수료 입금 모달
        this.$refs.feeModal.open(card);
      } else if (type === 'task') {
        // 작업 리스트 변경 모달
        this.$refs.taskListModal.open(card);
      } else if (type === 'task_list') {
        // 작업 리스트 링크 이동
        window.open(card.task_list, '_blank');
      } else if (type === 'sign') {
        // 서명한 계약서 링크 이동
        window.open(card.link, '_blank');
      } else if (type === 'review') {
        // 리뷰 작성 모달
        this.product = card.inquiry_detail;
        this.$refs.reviewModal.open();
      } else if (type === 'inquiry') {
        // 런치팩에 문의하기
        this.$router.push('/inquiry_reg?category=5');
      } else if (type === 'user') {
        // 고객정보 모달
        this.$refs.userModal.open(card);
      }
    },
    onUpdate() {
      this.page.cur_page = 1;
      this.getData();
    },
    getData() {
      const params = {
        page_num: this.page.cur_page,
        page_length: this.page.page_length,
      };
      if (this.selectedStatus !== undefined && this.selectedStatus !== null) {
        params.status = this.selectedStatus;
        console.log(params);
      }
      this.request[this.$route.path.includes('partner') ? 'partner' : 'user']
        .get('/launchpack/contract', {
          params: params,
        })
        .then((res) => {
          this.list = res.data.data;
          this.page.total = res.data.count;
          this.page.total_page = res.data.total_page;
        });
    },
    clickTab(value) {
      this.selectedStatus = value;
      this.getData();
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.my-mettings
  display flex
  justify-content center
  .container
    width 1152px
    padding 40px 0
    .mettings-title
      font-size 24px
      font-weight 700
      line-height 36px
      color $main
      margin-bottom 24px
    .tabs
      display flex
      gap 8px
      overflow-x auto
      flex-wrap nowrap
      .tab
        flex-shrink 0
        padding 7px 14px
        border-radius 50px
        border 1px solid $gray
        background-color white
        font-size 14px
        font-weight 500
        &.active
          background-color $main
          color white
    .list
      display flex
      flex-direction column
      padding 24px
      border-radius 12px
      background-color white

    .list-wrapper
      display flex
      flex-direction column
      gap 16px
      margin-top 24px
      .empty-view
        padding 100px 0
        font-size 16px
    .list-header
      background-color $gray3
      border-radius 8px
      display grid
      grid-template-columns 1.2fr 0.6fr 0.6fr 1.2fr 1.2fr
      gap 20px
      padding 12px 16px
      .list-header-item
        font-size 13px
        font-weight 500
        line-height 21px
        color $sub2

@media screen and (max-width: 1024px)
  .my-mettings
    .container
      width 100%
      padding 24px 0
      .mettings-title
        padding 0 16px
        font-size 18px
        line-height 24px
        margin-bottom 16px
      .tabs
        padding 0 16px
      .list-wrapper
        padding 0 16px
        .total-count
        .list-header
          display none
        .list
          background-color transparent
          gap 8px
          padding 0
</style>
