<template>
  <div class="contract-file-modal">
    <sweet-modal ref="modal" title="계약서 파일 업로드" overlay-theme="dark">
      <template v-if="contract">
        <div class="contract-file-content">
          <div class="body4 main margin-bottom-40">완성된 계약서를 첨부해 주시면 전자서명을 진행합니다.</div>
          <input-file :value.sync="file" label="계약서 파일"
            description=""
            :maxLength="1"></input-file>
        </div>
        <button class="button is-primary" @click="clickDone">완료</button>
      </template>
    </sweet-modal>
  </div>
</template>
<script>
import { SweetModal } from 'sweet-modal-vue';
import PartnerAPIMixin from '../../mixins/PartnerAPIMixin';
import InputFile from '../module/InputFile';
export default {
  name: "ContractFileUploadModal",
  components: {
    SweetModal,
    InputFile
  },
  mixins: [
    PartnerAPIMixin
  ],
  props: {},
  data() {
    return {
      contract: undefined,
      file: undefined
    }
  },
  methods: {
    open(contract) {
      this.contract = contract;
      this.file = contract.file;
      this.$refs.modal.open();
    },
    clickDone() {
      if(!this.file || this.file.length === 0) {
        this.toast('계약서 파일을 선택해주세요.');
        return;
      }
      this.$store.commit('setLoading', true);
      this.request.partner.patch(`launchpack/contract/${this.contract.id}`, { file: this.file[0], not_msg: true }).then(() => {
        this.$store.commit('setLoading', false);
        this.contract.file = this.file[0];
        this.toast('계약서 파일 업로드가 완료되었습니다.');
        this.$emit('done');
        this.$refs.modal.close();
      }).catch(() => {
        this.$store.commit('setLoading', false);
        this.toast('계약서 파일 업로드에 실패했습니다.');
      });

    }
  }
}
</script>
<style lang="stylus" scoped>
.contract-file-content
  white-space pre-wrap
.button
  width 100%
  margin-top 40px
  font-size 14px
</style>
<style lang="stylus">
.contract-file-modal .sweet-modal .sweet-title
  border-bottom 0
</style>