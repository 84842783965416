<template>
  <div class="contract-file-modal">
    <sweet-modal ref="modal" title="고객정보" overlay-theme="dark">
      <template v-if="contract">
        <div class="contract-file-content">
          <div class="body4 main">■ 이름 : {{  contract.user.name  }}<br>■ 전화번호 : {{  contract.user.phone  }}<br>■ 메일주소 : {{  contract.user.email  }}</div>
        </div>
        <button class="button" @click="clickReq">메세지에서 추가 정보 요청하기</button>
      </template>
    </sweet-modal>
  </div>
</template>
<script>
import { SweetModal } from 'sweet-modal-vue';
export default {
  name: "ContractUserModal",
  components: {
    SweetModal
  },
  data() {
    return {
      contract: undefined,
    }
  },
  methods: {
    open(contract) {
      this.contract = contract;
      this.$refs.modal.open();
    },
    clickReq() {
      this.$refs.modal.close();
      setTimeout(() => {
        let url = `/template_manufacture_detail?id=${this.contract.inquiry}`;
        if(this.$route.path.includes('partner_center')) {
          url += '&partner=true';
        }
        this.$router.push(url);
      }, 100);
    }
  }
}
</script>
<style lang="stylus" scoped>
.contract-file-content
  white-space pre-wrap
.button
  width 100%
  margin-top 40px
  font-size 14px
  font-weight 500
</style>
<style lang="stylus">
.contract-file-modal .sweet-modal .sweet-title
  border-bottom 0
</style>