<template>
  <div class="card-contract">
    <template v-if="isMobile">
      <!-- 상태 관련 메시지 -->
      <div class="flex-align">
        <div>
          <div :class="`tag-${card.status}`">{{ card.status_kor }}</div>
          <!-- 파트너 사이드 보조문구 -->
          <template v-if="isPartner">
            <!-- 계약 진행 예정 상태 -->
            <template v-if="card.status === 1">
              <div v-if="card.fee_status === 0" style="margin-top:8px" class="body6 error">계약 수수료를 입금해주세요</div>
              <div v-else-if="card.fee_status === 1" style="margin-top:8px" class="body6 sub3">입금 확인중이예요</div>
            </template>
            <!-- 계약 진행 중 상태 -->
            <template v-else-if="card.status === 2">
              <div v-if="!card.file" style="margin-top:8px" class="body6 error">계약서 파일을 업로드해주세요.</div>
              <div v-else-if="!card.link" style="margin-top:8px" class="body6 sub3">업로드된 계약서 파일로<br>전자계약을 요청드릴 예정이에요</div>
              <div v-else style="margin-top:8px">
                <div class="body6 error">계약서에 서명해주세요.</div>
                <div v-if="card.deposit_status === 1" class="body6 error">계약금 입금 내역을 확인해 주세요.</div>
              </div>
            </template>
          </template>
          <!-- 고객 사이드 보조문구 -->
          <template v-else>
            <!-- 계약 요청 -->
            <div v-if="card.status === 0" class="body6 error">계약 조건을 확인하고 진행여부를 결정해주세요.</div>
            <!-- 계약 진행예정 -->
            <div v-else-if="card.status === 1" class="body6 error">계약서를 작성할 때까지 기다려 주세요.</div>
            <!-- 계약 진행중 -->
            <template v-else-if="card.status === 2">
              <div v-if="card.link && card.deposit_status === 0" class="body6 error">계약서에 서명하고 파트너에게 계약금을 입금해 주세요.</div>
              <div v-else-if="card.link && card.deposit_status !== 0" class="body6 error">계약서에 서명해주세요.</div>
            </template>
          </template>
        </div>
      </div>
      <!-- 서비스명, 고객명 -->
      <div>
        <div class="body4-bold">{{ card.service_name }}</div>
        <div class="body5-medium">
          <div class="flex-align" v-if="isPartner" style="gap:8px">
            <div>{{ card.user.name}}</div>
            <div v-if="card.fee_status === 2"
            class="flex-align unselect" style="gap:4px;margin-top:2px" @click="clickButton('user')">
              <svg-icon icon="u_info-circle" color="#AEAEAE" :width="16" :height="16"></svg-icon>
              <div class="body5 sub3">고객정보</div>
            </div>
          </div>
          <div class="flex-align" v-else>{{ card.partner_nickname }}</div>
        </div>
      </div>
      <div>
        <!-- 계약금액 -->
        <div class="body4">계약금 : {{ currency(card.price) }}</div>
        <!-- 계약기간 -->
        <div class="body4">계약일 : {{  datesFormat(card.start_date, 'date_3')  }} ~ {{  datesFormat(card.end_date, 'date_3')  }}</div>
      </div>
      
    </template>
    <template v-if="!isMobile">
      <!-- 서비스명, 고객명 -->
      <div>
        <div class="body4-bold">{{ card.service_name }}</div>
        <div class="body4-medium">
          <div class="flex-align" v-if="isPartner" style="gap:8px">
            <div>{{ card.user.name}}</div>
            <div v-if="card.fee_status === 2"
            class="flex-align unselect" style="gap:4px;margin-top:2px" @click="clickButton('user')">
              <svg-icon icon="u_info-circle" color="#AEAEAE" :width="16" :height="16"></svg-icon>
              <div class="body5 sub3">고객정보</div>
            </div>
          </div>
          <div class="flex-align" v-else>{{ card.partner_nickname }}</div>
        </div>
      </div>
      <!-- 계약금액 -->
      <div>
        <div class="flex-end">
          <div class="body4" style="padding-right:24px">{{ currency(card.price) }}</div>
        </div>
      </div>
      <!-- 계약기간 -->
      <div class="body4">{{  datesFormat(card.start_date, 'date_3')  }}<br>~ {{  datesFormat(card.end_date, 'date_3')  }}</div>
      <!-- 상태 관련 메시지 -->
      <div class="flex-align">
        <div>
          <div :class="`tag-${card.status}`">{{ card.status_kor }}</div>
          <template v-if="isPartner">
            <!-- 계약 진행 예정 상태 -->
            <template v-if="card.status === 1">
              <div v-if="card.fee_status === 0" style="margin-top:8px" class="body6 error">계약 수수료를 입금해주세요</div>
              <div v-else-if="card.fee_status === 1" style="margin-top:8px" class="body6 sub3">입금 확인중이예요</div>
            </template>
            <!-- 계약 진행 중 상태 -->
            <template v-else-if="card.status === 2">
              <div v-if="!card.file" style="margin-top:8px" class="body6 error">계약서 파일을 업로드해주세요.</div>
              <div v-else-if="!card.link" style="margin-top:8px" class="body6 sub3">업로드된 계약서 파일로<br>전자계약을 요청드릴 예정이에요</div>
              <div v-else style="margin-top:8px">
                <div class="body6 error">계약서에 서명해주세요.</div>
                <div v-if="card.deposit_status === 1" class="body6 error">계약금 입금 내역을 확인해 주세요.</div>
              </div>
            </template>
          </template>
          <!-- 고객 사이드 보조문구 -->
          <template v-else>
            <!-- 계약 요청 -->
            <div v-if="card.status === 0" class="body6 error">계약 조건을 확인하고 진행여부를 결정해주세요.</div>
            <!-- 계약 진행예정 -->
            <div v-else-if="card.status === 1" class="body6 error">계약서를 작성할 때까지 기다려 주세요.</div>
            <!-- 계약 진행중 -->
            <template v-else-if="card.status === 2">
              <div v-if="card.link && card.deposit_status === 0" class="body6 error">계약서에 서명하고 파트너에게 계약금을 입금해 주세요.</div>
              <div v-else-if="card.link && card.deposit_status !== 0" class="body6 error">계약서에 서명해주세요.</div>
            </template>
          </template>
        </div>
      </div>
    </template>
    <div class="grid-buttons">
      <div class="button-wrap">
        <!-- 계약 요청 -->
        <button v-if="card.status === 0" class="button is-dark button-contract" @click="clickButton('condition')">계약조건 보기</button>
        <!-- 진행 안함, 취소 상태 -->
        <button v-else-if="card.status >= 100" class="button button-contract" @click="clickButton('condition')">계약조건</button>
        <!-- 파트너 사이드 기능 버튼 -->
        <template v-if="isPartner">
          <!-- 계약 진행 예정-->
          <template v-if="card.status === 1">
            <button v-if="card.fee_status < 3" :class="{'is-dark': card.fee_status === 0}" class="button button-contract" @click="clickButton('fee')">입금정보</button>
          </template>
          <!-- 계약 진행중 -->
          <template v-else-if="card.status === 2">
            <button v-if="!card.file" class="button is-dark button-contract" @click="clickButton('file')">계약서 업로드</button>
            <button v-else-if="card.file && !card.link" class="button button-contract" @click="clickButton('file')">계약서 수정</button>
            <button v-else-if="card.link" class="button is-dark button-contract" @click="clickButton('link')">계약서 보기</button>
            <button v-if="card.deposit_status === 1" class="button is-dark button-contract" @click="clickButton('deposit_status_2')">입금확인 완료</button>
            <button v-else-if="card.deposit_status === 2" class="button is-gray5 button-contract"><i class="material-icons" style="font-size:15px;margin-right:4px">check</i> 입금확인 완료</button>
          </template>
          
          <button v-else-if="card.status === 3" class="button is-dark button-contract" @click="clickButton('status_4')">계약 이행</button>
          <button v-else-if="card.status === 4" class="button is-dark button-contract" @click="clickButton('status_5')">계약 이행완료</button>
        </template>
        <!-- 유저 사이드 기능 버튼 -->
        <template v-else>
          <!-- 계약 진행예정 -->
          <button v-if="card.status === 1" class="button button-contract" @click="clickButton('condition')">계약조건</button>
          <!-- 계약 진행중 -->
          <template v-else-if="card.status === 2">
            <button v-if="card.link" class="button is-dark button-contract" @click="clickButton('link')">계약서 보기</button>
            <button v-if="card.deposit_status === 0" class="button is-dark button-contract" @click="clickButton('deposit_status_1')">입금완료</button>
            <button v-else-if="card.deposit_status >= 1" class="button is-gray5 button-contract"><i class="material-icons" style="font-size:15px;margin-right:4px">check</i> 입금완료</button>
          </template>
          <!-- 계약 확정, 후기 작성 전 -->
          <button v-if="card.status === 5 && !card.has_review" class="button is-dark button-contract" @click="clickButton('review')">후기작성</button>
        </template>
        <!-- 계약완료 이후 -->
        <button v-if="card.status >= 3 && card.status <= 5" class="button" style="width:100%" @click="clickButton('task_list')">작업 리스트</button>
      </div>
      <button
        class="button flex-center"
        style="width:40px"
        @click="clickInquiry"
      >
        <div class="position-relative" style="height:18px">
          <img
            src="/static/icon/u_comment.svg"
            style="width:18px;height:18px"
          />
          <div class="new-noti" v-if="card.inquiry_detail.unread"></div>
        </div>
      </button>
      
      <div class="position-relative">
        <button class="button" @click.stop="vContext=true">
          <i class="material-icons">more_horiz</i>
        </button>
        <div class="context-menu" v-if="vContext" @click.stop.prevent="contextHide" v-click-outside="contextHide">
          <div class="context-menu-item" @click.stop.prevent="clickButton('inquiry')">런치팩에 문의하기</div>
          <div v-if="card.status >= 3" class="context-menu-item" @click.stop.prevent="clickButton('sign')">서명한 계약서</div>
          <div v-if="card.status >= 3 && isPartner" class="context-menu-item" @click.stop.prevent="clickButton('task')">작업 리스트 변경</div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import SvgIcon from '@/components/component/SvgIcon.vue';
export default {
  name: "CardContract",
  components: {SvgIcon},
  mixins: [],
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      vContext: false
    };
  },
  computed: {
    isPartner() {
      return this.$route.path.includes('partner_center');
    }
  },
  watch: {},
  mounted() {},
  methods: {
    contextHide() {
      this.vContext = false;
    },
    clickButton(type) {
      this.$emit('clicked', {card: this.card, type: type});
      this.contextHide();
    },
    clickInquiry() {
      let url = `/template_manufacture_detail?id=${this.card.inquiry}`;
      if(this.$route.path.includes('partner_center')) {
        url += '&partner=true';
      }
      this.$router.push(url);
    }
  },
};
</script>
<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.card-contract
  display grid
  grid-template-columns 1.2fr 0.6fr 0.6fr 1.2fr 1.2fr
  gap 20px
  padding 12px 16px
  border-bottom 1px solid $gray
  color $main
.ellipsis-1
  height 18px
.new-noti
  position absolute
  top -1px
  right -2px
  width 10px
  height 10px
  border-radius 50%
  background-color $error
  border 2px solid white

.grid-buttons
  display grid
  grid-template-columns 1fr 40px 40px
  align-items center
  gap 8px
  .button-wrap
    display flex
    align-items center
    gap 8px
    flex-wrap wrap
  .button
    width 100%
    height 40px
    align-items center
    justify-content center
    display flex
.button-contract
  flex 1
  min-width 100px
  padding 0 8px
  height 40px
.tag-0
.tag-1
.tag-2
.tag-3
.tag-4
.tag-5
.tag-101
  border-radius 8px
  padding 2px 8px
  font-size 12px
  font-weight 700
  width fit-content
.tag-0
  color $yellow
  background-color $yellow50
.tag-1
.tag-2
  color $error
  background-color $error50
.tag-3
.tag-4
  color $info
  background-color $info50
.tag-5
.tag-100
.tag-101
.tag-102
  color $sub2
  background-color $gray3

.context-menu
  position absolute
  right 0
  width 140px
  background-color white
  border-radius 8px
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.20)
  z-index 100
  overflow hidden
  .context-menu-item
    padding 8px 12px
    font-size 13px
    color $main
    border-bottom 1px solid $gray2
    cursor pointer
    &:hover
      background-color $gray4
    &:last-child
      border-bottom 0
@media screen and (max-width: 1024px)
  .card-contract
    grid-template-columns 1fr
    border 1px solid $gray
    border-radius 8px
    background-color white
    gap 8px
    padding 16px
  .grid-buttons
    .button-wrap
      flex-wrap nowrap
</style>
